import React from 'react'
import Layout from '../components/layout'
import MainWrap from '../components/mainwrap'
import H1 from '../components/h1'
import Button from '../components/button'
import { UnorderedList } from '../styles/common'
import { navigate } from 'gatsby'
import useCampaignData from "../graphql/queries"
import { Margin } from 'styled-components-spacing'
  
export default function WinnerPage({ data }) {
    const { sanityCampaign } = useCampaignData()

    return (
  <Layout>
    <MainWrap>
    <H1 title="Vinnere"></H1>
    { sanityCampaign.length > 0 
    ? <UnorderedList>
    { sanityCampaign.winners.map((winner, index) => (
      <li key={index}><strong>Uke {winner.week}</strong>: {winner.name}</li>
        ))}
    </UnorderedList>
    : <p>Ingen vinnere trukket enda</p> }
    <Margin top={5}>
        <Button title="Tilbake til forsiden" handleClick={ () => { navigate("/") }} answers={null} outline={true} small={true}></Button>
    </Margin>
    </MainWrap>

    </Layout>
)}